/* .App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */

.breadcrumb {
	padding: 8px 15px;
	margin-bottom: 20px;
	list-style: none;
	background-color: #f5f5f5;
	border-radius: 4px;
}

/* .Mui-form-label {
	color: #21557a !important;
	font-size: 0.9rem !important;
	line-height: 1.9rem !important;
	display: block !important;
	margin-bottom: 5px !important;
	font-weight: bold !important;
	font-family: "Montserrat", sans-serif !important;
	transform: translate(0, -0.9rem) scale(1) !important;
} */

/* .Mui-form-input > input,
.Mui-form-input > textarea,
.Mui-form-input > select,
.Mui-form-input > div {
	color: #555555 !important;
	font-family: "Montserrat", sans-serif !important;
}

.MuiSelect-select,
.MuiMenuItem-root {
	color: #555555 !important;
	font-family: "Montserrat", sans-serif !important;
} */

.has-star {
	display: inline-block !important;
	font-weight: 400 !important;
	font-size: 1rem !important;
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
		"Courier New", monospace !important;
	color: #ef4444 !important;
}

/* .has-star::after {
    content: "*" !important;
    margin-left: 3px !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace !important;
    color: #ef4444 !important;
} */

.Mui-form-label.Mui-form-label-required::after {
	content: "*" !important;
	margin-left: 3px !important;
	font-weight: 400 !important;
	font-size: 1rem !important;
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
		"Courier New", monospace !important;
	color: #ef4444 !important;
}

.Mui-form-label.Mui-form-label-required::after.Mui-error {
	content: "*" !important;
	margin-left: 3px !important;
	font-weight: 400 !important;
	font-size: 1rem !important;
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
		"Courier New", monospace !important;
	color: #ef4444 !important;
}

.Mui-error {
	font-size: 0.9rem !important;
	/* font-family: "Montserrat", sans-serif !important; */
}

p.Mui-error {
	color: #ef4444 !important;
	margin-top: 0.25rem;
	font-size: 0.9rem !important;
	line-height: 1.6 !important;
	/* font-family: "Montserrat", sans-serif !important; */
	margin-bottom: 0 !important;
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.9rem;
	line-height: 1.6 !important;
	color: #ef4444;
}

.is-invalid.invalid-feedback {
	display: block;
}

select.form-control.is-invalid {
	padding-right: 0rem !important;
	background-position: right calc(0.375em + 0.1875rem) center !important;
}

select.form-control.is-valid {
	padding-right: 0rem !important;
	background-position: right calc(0.375em + 0.1875rem) center !important;
}

.PhoneInputCountry {
	height: 36px !important;
	align-self: end !important;
	position: relative !important;
	display: flex !important;
	align-items: center !important;
	margin-right: var(--PhoneInputCountrySelect-marginRight) !important;
}

.form-group > .PhoneInput > .PhoneInputCountry {
	align-self: center !important;
	left: 10px !important;
	z-index: 1 !important;
}

.g-recaptcha > div {
	width: 100% !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
	padding-top: 10px !important;
}
@media screen and (max-width: 374px) {
	#rc-imageselect,
	.g-recaptcha > div {
		transform: scale(0.825);
		-webkit-transform: scale(0.825);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		width: 130% !important;
		margin-bottom: -15px !important;
	}
}

#solutions-dropdown:focus {
	outline: none !important;
	box-shadow: none !important;
}

#solutions-dropdown:active {
	border-color: transparent !important;
}

.btn.show {
	border-color: transparent !important;
}

.filepond--credits {
	display: none !important;
}

.btn-xs,
.btn-group-xs > .btn {
	padding: 1px 5px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

@media (min-width: 640px) {
	.sm\:mt-0 {
		margin-top: 0px !important;
	}
}

@keyframes popout {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.02);
	}
	100% {
		transform: scale(1);
	}
}
/* Confirm alert styles */
.react-confirm-alert {
	border-top: 3px solid #00abf9;
	border-radius: 5px;
}
.react-confirm-alert-button-group > button {
	background-color: #00abf9;
}
.input-label {
	font-family: Lato, sans-serif !important;
	font-weight: 400;
	font-size: 1rem;
	color: rgb(33, 85, 122);
}

.blue-header {
	background-color: #003b66 !important; /* Bootstrap primary blue */
	color: white !important;
	text-align: center;
}

.customScrollbar {
	/* For Chrome, Safari, Opera */
	&::-webkit-scrollbar {
		width: 5px; /* Adjust the width of the scrollbar */
	}

	&::-webkit-scrollbar-track {
		background: transparent; /* Makes the track (background) transparent */
	}

	&::-webkit-scrollbar-thumb {
		background: #888; /* Scroll handle color */
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555; /* Scroll handle color on hover */
	}

	/* For Firefox */
	scrollbar-width: thin; /* Makes the scrollbar thin */
	scrollbar-color: #888 transparent; /* Scroll handle color and track color */
}

.rounded-table {
	border-radius: 15px; /* Ensure the border radius applies to children */
	overflow: hidden; /* Ensure the border-radius applies to children */
	border: none !important; /* Remove borders */
}

.rounded-table thead tr:first-child th:first-child {
	border-top-left-radius: 15px;
}

.rounded-table thead tr:first-child th:last-child {
	border-top-right-radius: 15px;
}

.rounded-table tbody tr:last-child td:first-child {
	border-bottom-left-radius: 15px;
}

.rounded-table tbody tr:last-child td:last-child {
	border-bottom-right-radius: 15px;
}

.rounded-table th,
.rounded-table td {
	border: none !important;
}

@keyframes slideInFromTop {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideOutToTop {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(-100%);
		opacity: 0;
	}
}

.modal-dialog-transition {
	animation-duration: 0.5s; /* Adjust the time to make it slower */
	animation-fill-mode: forwards;
}

.modal-enter {
	animation-name: slideInFromTop;
}

.modal-exit {
	animation-name: slideOutToTop;
}

.modal-backdrop-spinner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(
		255,
		255,
		255,
		0.8
	); /* Semi-transparent white backdrop */
	z-index: 10;
}

.custom-spinner {
	width: 5rem; /* Custom spinner size */
	height: 5rem; /* Custom spinner size */
	border: 0.5em solid #003b66; /* Sets border color to the color inherited by the parent */
	border-right-color: transparent; /* Makes one side of the spinner transparent */
}

/* This will hide the spin button in number inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield; /* Firefox */
}

.custom-modal-title {
	width: 100%;
	text-align: center;
	margin: 0 auto;
	font-family: "Open Sans", sans-serif;
	font-size: 18px;
	font-weight: 400;
}

.radio-pointer {
	cursor: pointer;
}

.form-label-custom {
	display: flex;
	margin-bottom: 10px;
	font-family: "Lato", sans-serif;
	color: #21557a;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 400;
	letter-spacing: 0.5px;
}

.change-password_text {
	margin-bottom: 10px;
	font-family: "Open Sans", sans-serif;
	color: #33454b;
	font-weight: 400;
}

@keyframes slideDown {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}

.alert-slide-down {
	animation: slideDown 0.5s ease forwards;
	width: 100%; /* Set width to 100% */
	box-sizing: border-box; /* Include padding and borders in the element's width */
}

.not-found-text {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-text-size-adjust: 100%;
	font-family: "Open Sans", sans-serif;
	color: #44545a;
	font-size: 18px;
	line-height: 24px;
	box-sizing: border-box;
	font-weight: bold;
}

.search-results-block {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-text-size-adjust: 100%;
	font-size: 14px;
	line-height: 20px;
	font-family: "Lato", sans-serif;
	text-align: center;
	box-sizing: border-box;
	color: #337ab7;
	display: inline-block;
	position: relative;
	margin-right: 1.65%;
	margin-left: 1.65%;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 0 3px 25px -5px rgba(98, 125, 135, 0.2);
	transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
	text-decoration: none;
	width: 100%;
	max-width: 300px;
	min-height: auto;
	padding-bottom: 1px;
}

.search-card {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-text-size-adjust: 100%;
	font-size: 14px;
	line-height: 20px;
	color: #333;
	font-family: "Lato", sans-serif;
	box-sizing: border-box;
	width: 47%;
	margin-right: 1.5%;
	margin-bottom: 25px;
	margin-left: 1.5%;
	padding: 20px;
	border-left: 3px none #00abf9;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: -4px 4px 15px -5px rgba(186, 212, 224, 0.68);
}

.card-title-search {
	margin-top: 0px;
	margin-bottom: 20px;
	font-family: "Open Sans", sans-serif;
	color: #33454b;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
}

.referral-label {
	display: block;
	margin-bottom: 10px;
	font-family: Lato, sans-serif;
	color: #21557a;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 400;
	letter-spacing: 0.5px;
}

.referral-text {
	margin-bottom: 10px;
	font-family: "Open Sans", sans-serif;
	color: #33454b;
	font-weight: 400;
}

.table-striped-rto tbody tr:nth-of-type(even) {
	background-color: #eff3f7;
}

.table-striped-rto tbody tr:nth-of-type(odd) {
	background-color: #fafafa;
}

.custom-button {
	background-color: #22557b;
	border: none;
}
.custom-button:hover {
	background-color: #397ba6; /* Slightly lighter shade */
}

.scrollable-tbody {
	display: block;
	/* width: 100%; */
	height: 300px; /* Adjust this value based on your row height */
	overflow-y: scroll;
}

.scrollable-table-container {
	/* height: 350px;  */
	/* overflow-y: auto; */
	border-radius: 20px;
}

.scrollable-table-container thead th {
	position: sticky;
	top: 0;
	background-color: white; /* Or any color you prefer */
}

.bookmarkBtn {
	width: 70px;
	height: 30px;
	border-radius: 40px;
	border: 1px solid rgba(255, 255, 255, 0.349);
	background-color: rgb(12, 12, 12);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition-duration: 0.3s;
	overflow: hidden;
}

.IconContainer {
	width: 25px;
	height: 25px;
	background: #397ba6;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	z-index: 2;
	transition-duration: 0.3s;
}

.icon {
	border-radius: 1px;
}

.text {
	height: 100%;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	z-index: 1;
	transition-duration: 0.3s;
	font-size: 1em;
	padding: 0px !important;
	margin: 0px !important;
}

.bookmarkBtn:hover .IconContainer {
	width: 65px;
	border-radius: 40px;
	transition-duration: 0.3s;
}

.bookmarkBtn:hover .text {
	transform: translate(10px);
	width: 0;
	font-size: 0;
	transition-duration: 0.3s;
}

.bookmarkBtn:active {
	transform: scale(0.95);
	transition-duration: 0.3s;
}
.custom-date-input-container {
	width: 30%;
}
.readonly-text {
	background-color: rgba(0, 0, 0, 0.05);
}

@media (max-width: 600px) {
	.custom-date-input-container {
		width: 100%;
	}
}

.titled {
	border: solid #bce8f1;
	border-radius: 5px 5px 0px 0px;
	padding-bottom: 10px;
}
