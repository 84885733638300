.bin-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.outer-div {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	color: white;
	border-radius: 10px;
}

.outer-div:hover .gear {
	animation: rotate 2s linear infinite;
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.gear {
	fill: white;
}

/***********************************************************/
/***********************************************************/

.bin-button-danger {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.bin-bottom-danger {
	width: 15px;
}

.bin-top-danger {
	width: 17px;
	transform-origin: right;
	transition-duration: 0.3s;
}

.bin-button-danger:hover .bin-top-danger {
	transform: rotate(45deg);
}

.outer-div-danger {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	color: white;
	border-radius: 10px;
}

.outer-div-danger:hover .bin-top-danger {
	transform: rotate(45deg);
}

/***********************************************************/
/***********************************************************/

.phone-svg{
	fill:white;
}

/***********************************************************/
/***********************************************************/

.loading-backdrop {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
}