.css-15wwp11-MuiTableHead-root {
	display: table-header-group;
	background: #eff3f7;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
	background-color: #fff !important;
	color: rgba(0, 0, 0, 0.87) !important;
	border-radius: 10px !important;
	/* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12); */
	width: 100%;
	border: solid #eff3f7 !important;
	overflow-x: auto;
	box-shadow: none !important;
	/* text-align: center; */
}

.css-1azl6jz-MuiTableCell-root {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.5rem;
	letter-spacing: 0.01071em;
	display: table-cell;
	vertical-align: inherit;
	border: 1px solid #eff3f7 !important;
	text-align: center !important;
	padding: 16px;
	color: rgba(0, 0, 0, 0.87);
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.css-1ygcj2i-MuiTableCell-root {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.5rem;
	letter-spacing: 0.01071em;
	display: table-cell;
	vertical-align: inherit;
	border: 1px solid #eff3f7 !important;
	text-align: center !important;
	padding: 16px;
	color: rgba(0, 0, 0, 0.87);
}

.css-177gid-MuiTableCell-root {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.43;
	letter-spacing: 0.01071em;
	display: table-cell;
	vertical-align: inherit;
	border: 1px solid #eff3f7 !important;
	text-align: center !important;
	padding: 16px;
	color: rgba(0, 0, 0, 0.87);
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

 .css-1ex1afd-MuiTableCell-root {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.43;
	letter-spacing: 0.01071em;
	display: table-cell;
	vertical-align: inherit;
	border: 1px solid #eff3f7 !important;
	text-align: center !important;
	padding: 16px;
	color: rgba(0, 0, 0, 0.87);
}


.parent .tss-tlx3x1-MUIDataTableToolbar-root {
	background: #eff3f7;
	border-radius: 10px 10px 0px 0px;
}