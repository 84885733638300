.bordered-table {
	border-collapse: collapse;
	width: 100%;
	margin: 10px 0px;
}

.bordered-table td,
.bordered-table th {
	border: 1px solid black;
	padding: 8px;
}
.ul-upper-alpha {
	list-style-type: upper-alpha;
	margin: 10px 0px;
}
.ul-upper-alpha li {
	padding: 5px;
}
.ul-decimal {
	list-style-type: decimal;
	margin: 10px 0px;
}
.ul-decimal li {
	padding: 5px;
}
.ul-decimal li p {
	padding-top: 10px;
}
.ul-square {
	list-style-type: square;
	margin: 10px 0px;
}
.ul-square li {
	padding: 5px;
}
